import React from "react";

export default class TodoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTodo: '',
    };

  }
  handleChange = (e) => {
    this.setState({newTodo: e.target.value});
  }
  addTodo = () => {
    if (this.state.newTodo === ''){
      return;
    }
    this.props.addTodo(this.state.newTodo);
    this.setState({newTodo: ''});
  }
  render() {
    return (
      <React.Fragment>
        <input value={this.state.newTodo} onChange={this.handleChange}/>
        <button onClick={this.addTodo}>追加</button>
      </React.Fragment>
    )
  }
}
