import React from 'react';
import { Route } from "react-router-dom";
import TodoForm from './components/TodoForm';
import TodoList from './components/TodoList';
import TodoForm2 from './components/TodoForm2';
import TodoList2 from './components/TodoList2';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todos: [],
    };
  }

  addTodo = (todo) => {
    const todos = this.state.todos;
    todos.push(todo);
    this.setState({todos: todos});
    localStorage.setItem('todos', JSON.stringify(this.state.todos));
  }
  deleteTodo = (index) => {
    const todos = this.state.todos;
    todos.splice(index, 1);
    this.setState({todos: todos});
    localStorage.setItem('todos', JSON.stringify(this.state.todos));
  }
  render() {
    return (
      <div>
        <header>
          <h1>Todoアプリ</h1>
        </header>
        <main>
          <TodoForm addTodo={this.addTodo}/>
          <TodoList deleteTodo={this.deleteTodo} todos={this.state.todos}/>
          <hr/>
          <h2>コンポーネントの独立</h2>
          <TodoForm2/>
          <TodoList2/>
          <hr/>
          <h2>ルーティングの利用</h2>
          <Route path="/add" component={TodoForm2} exact></Route>
          <Route path="/" component={TodoList2} exact></Route>
        </main>
      </div>
    );
  }
}
