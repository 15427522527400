import React from "react";

export default class TodoList extends React.Component {
  deleteTodo = (e) => {
    this.props.deleteTodo(Number(e.target.dataset.index));
  }
  render() {
    return (
      <React.Fragment>
        <h2>Todoリスト</h2>
        <ul>
          {
            this.props.todos.map((todo, i) => {
              return (
                <li key={i}>{todo}<button onClick={this.deleteTodo} data-index={i}>削除</button></li>
              );
            })
          }
        </ul>
      </React.Fragment>
    )
  }
}
