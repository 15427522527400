import React from "react";
import { Link } from "react-router-dom";

export default class TodoForm2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTodo: '',
    };
  }

  handleChange = (e) => {
    this.setState({newTodo: e.target.value});
  }

  addTodo = () => {
    if (this.state.newTodo === '') {
      return;
    }
    const todos = JSON.parse(localStorage.getItem('todos2')) || [];
    todos.push(this.state.newTodo);
    localStorage.setItem('todos2', JSON.stringify(todos));
    this.setState({newTodo: ''});
  }

  render() {
    return (
      <React.Fragment>
        <Link to="/">一覧へ</Link>
        <input value={this.state.newTodo} onChange={this.handleChange}/>
        <button onClick={this.addTodo}>追加</button>
      </React.Fragment>
    )
  }
}
