import React from "react";
import { Link } from "react-router-dom";

export default class TodoList2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todos: [],
    };
  }

  deleteTodo = (e) => {
    const todos = this.state.todos;
    todos.splice(Number(e.target.dataset.index), 1);
    this.setState({todos: todos});
    localStorage.setItem('todos2', JSON.stringify(todos));
  }

  componentDidMount() {
    const todos = JSON.parse(localStorage.getItem('todos2')) || [];
    this.setState({todos: todos});
  }

  render() {
    return (
      <React.Fragment>
        <Link to="/add">登録フォームへ</Link>
        <h2>Todoリスト</h2>
        <ul>
          {
            this.state.todos.map((todo, i) => {
              return (
                <li key={i}>{todo}
                  <button onClick={this.deleteTodo} data-index={i}>削除</button>
                </li>
              );
            })
          }
        </ul>
      </React.Fragment>
    )
  }
}
